<template>
  <div id="page">
    <ion-card class="card card__theme">
      <ion-row>
        <ion-col>
          <h4>Risultati Analisi dei Talenti</h4>
        </ion-col>
      </ion-row>
    </ion-card>
    <ion-grid v-if="usersReady" class="card">
      <ion-row>
        <ion-col size="4" v-for="user in list" :key="user.ID_User">
          <div @click="callAnalisiTalento(user.ID_User)" class="user-container">
            <div class="user-avatar">
              <img
                v-if="user.UserImage.length"
                :src="user.UserImage"
                class="avatar"
                @error="handleImgError"
              />
              <img
                v-else
                src="../../public/assets/img/avatar_placeholder.png"
                class="avatar"
                @error="handleImgError"
              />
            </div>
            <div class="user-name">
              <p>{{ user.UserString }}</p>
            </div>
            <div class="user-role">
              <p>{{ user.RuoloUtente }}</p>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid class="card card__barre-statistiche" id="card">
      <ion-row>
        <ion-col>
          <!-- {{ tempId }} -->
          <analisi-del-talento
            v-if="tempId"
            :id="tempId"
            :colors="colorsAnalisiDelTalento"
          ></analisi-del-talento>
          <div v-else>
            <p>Analisi del talento non presente</p>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import ApiService from "../common/service.api";
import AnalisiDelTalento from "../components/AnalisiDelTalento.vue";
import UserService from "../common/mixins/User";

import {
  IonButton,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonList,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";

export default {
  name: "RisultatiAnalisiDeiTalenti",
  components: {
    IonButton,
    IonCheckbox,
    IonItem,
    IonLabel,
    IonList,
    IonCard,
    IonGrid,
    IonRow,
    IonCol,
    AnalisiDelTalento,
  },
  mixins: [UserService],

  created() {
    this.usersReady = false;
    this.getCurrentUser().then(
      (res) => (this.currentUserIdCompany = res.id_company)
    );
  },
  mounted() {
    ApiService.get("AnalisiDelTalentoUserGroup").then((res) => {
      this.list = res.data;
      console.log(this.list);
      this.usersReady = true;
    });
  },
  data() {
    return {
      usersReady: false,
      list: [],
      tempId: 0,
      currentUserIdCompany: 0,
    };
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    callAnalisiTalento(id) {
      this.resetTempId();
      this.tempId = id;
      this.scrollDown();
    },
    resetTempId() {
      this.tempId = false;
      return this.tempId;
    },
    scrollDown() {
      setTimeout(() => {
        document
          .querySelector("#page")
          .lastChild.scrollIntoView({ behavior: "smooth" });
      }, 1500);
    },
  },
  computed: {
    colorsAnalisiDelTalento() {
      return [
        this.whappyClientConfig.primaryColor,
        this.whappyClientConfig.secondaryColor,
        this.whappyClientConfig.thirdaryColor,
      ];
    },
    //     computed_UsersList() {
    //       let array = [];
    //       this.list.forEach((element) => {
    //         if (element.id_company == this.currentUserIdCompany) {
    //           array.push(element);
    //         }
    //       });
    // return array;
    //     },
  },
};
</script>

<style scoped>
ion-icon {
  font-size: 5rem;
  color: var(--text-color-light);
}

p {
  font-size: 0.8rem;
}

.card {
  border-radius: 15px;
  padding: 1rem;
  margin: 1rem 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.card__barre-statistiche {
  background: #f7f7f7;
  padding: 0;
}

.card__theme {
  color: var(--text-color-light);
}

.user-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-avatar {
  width: 80%;
  display: grid;
  place-items: center;
}

.user-avatar img {
  width: 100%;
  border-radius: 50%;
  aspect-ratio: 1;
  object-fit: cover;
}

.user-name {
  text-align: center;
}
</style>
